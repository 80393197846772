import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.dialogOpenModel,"max-width":_vm.width,"scrollable":"","persistent":_vm.persistent},on:{"click:outside":function($event){_vm.persistent ? void 0 : _vm.$emit('update:dialog-open-model',false)}}},[_c(VCard,[_c(VCardTitle,{staticClass:"cardTitle justify-center text-h5"},[_vm._t("title"),(!_vm.hideClose)?_c(VBtn,{staticClass:"closeIcon",attrs:{"icon":"","small":"","title":"关闭"},on:{"click":function($event){return _vm.$emit('update:dialog-open-model',false)}}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" mdi-close ")])],1):_vm._e()],2),_c(VDivider),_c(VCardText,{class:_vm.bodyClass,style:(("height: " + _vm.height + ";"))},[_vm._t("body")],2),_c(VDivider),_c(VCardActions,[_vm._t("actions")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }